@font-face {
  font-family: 'FSRufus-Light';
  src: url('fonts/FSRufus-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'FSRufus';
  src: url('fonts/FSRufus.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'FSRufus-Bold';
  src: url('fonts/FSRufus-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: 'FSRufus', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link, a:visited {
  color: inherit;
}

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    transform: rotate(180deg) scale(1.5);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media print
{
  .noPrint, .noPrint *
  {
    display: none !important;
  }
}
